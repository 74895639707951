const huntMilestones = [
  {
    order: 1,
    name: "Active",
    statusLabel: "Active",
    value: "active",
  },
  {
    order: 2,
    name: "Fulfilled",
    statusLabel: "Fulfilled",
    value: "fulfilled",
  },
  {
    order: 3,
    name: "Ceased",
    statusLabel: "Ceased",
    value: "ceased",
  },
];
const contractMilestones = [
  {
    order: 0,
    name: "Executed",
    statusLabel: "Executed",
    value: "executed",
    openDateFieldName: ["executed_date"],
    closeDateFieldName: ["commenced_date", "terminated_date", "expired_date"],
  },
  {
    order: 1,
    name: "Commenced",
    statusLabel: "Commenced",
    value: "commenced",
    openDateFieldName: ["commenced_date"],
    closeDateFieldName: ["terminated_date", "expired_date"],
  },
  {
    order: 2,
    name: "Terminated",
    statusLabel: "Terminated",
    value: "terminated",
    openDateFieldName: ["terminated_date"],
    closeDateFieldName: ["ignore_date"],
  },
  {
    order: 3,
    name: "Expired",
    statusLabel: "Expired",
    value: "expired",
    openDateFieldName: ["expired_date"],
    closeDateFieldName: ["ignore_date"],
  },
];
const occupancyMilestones = [
  {
    order: 1,
    name: "Moved In",
    statusLabel: "Moved In",
    value: "move_in",
  },
  {
    order: 2,
    name: "Moved Out",
    statusLabel: "Moved Out",
    value: "move_out",
  },
];
const parcelMilestones = [
  {
    order: 1,
    name: "Created",
    statusLabel: "Created",
    value: "created",
  },
  {
    order: 2,
    name: "Destroyed",
    statusLabel: "Destroyed",
    value: "destroyed",
  },
];
const airRightMilestones = [
  {
    order: 1,
    name: "Granted",
    statusLabel: "Granted",
    value: "granted",
  },
  {
    order: 2,
    name: "Used",
    statusLabel: "Used",
    value: "used",
  },
];
const developmentMilestones = [
  {
    order: 1,
    name: "Hypothetical",
    statusLabel: "Hypothetical",
    value: "hypothetical",
  },
  {
    order: 2,
    name: "Proposed construction",
    statusLabel: "Proposed construction",
    analyzeLabel: "Proposed",
    value: "construction_proposed",
  },
  {
    order: 3,
    name: "Construction approved",
    statusLabel: "Construction approved",
    analyzeLabel: "Approved",
    value: "construction_approved",
  },
  {
    order: 4,
    name: "Withdrawn",
    statusLabel: "Withdrawn",
    value: "withdrawn",
  },
  {
    order: 5,
    name: "Construction permit",
    statusLabel: "Construction permit",
    analyzeLabel: "Approved",
    value: "construction_permitted",
  },
  {
    order: 6,
    name: "Construction commenced",
    statusLabel: "Construction commenced",
    analyzeLabel: "Under Construction",
    value: "construction_commenced",
  },
  {
    order: 7,
    name: "Construction completed",
    statusLabel: "Construction completed",
    analyzeLabel: "Completed",
    value: "construction_completed",
  },
  {
    order: 8,
    name: "Proposed demolition",
    statusLabel: "Proposed demolition",
    analyzeLabel: "Completed",
    value: "demolition_proposed",
  },
  {
    order: 9,
    name: "Demolition permit",
    statusLabel: "Demolition permit",
    analyzeLabel: "Completed",
    value: "demolition_permitted",
  },
  {
    order: 10,
    name: "Demolished",
    statusLabel: "Demolished",
    value: "demolished",
  },
];
const floorAreaMilestones = [
  {
    order: 1,
    name: "Hypothetical",
    statusLabel: "Hypothetical",
    value: "hypothetical",
  },
  {
    order: 2,
    name: "Proposed construction",
    statusLabel: "Proposed construction",
    analyzeLabel: "Proposed",
    value: "construction_proposed",
  },
  {
    order: 3,
    name: "Construction approved",
    statusLabel: "Construction approved",
    analyzeLabel: "Approved",
    value: "construction_approved",
  },
  {
    order: 4,
    name: "Withdrawn",
    statusLabel: "Withdrawn",
    value: "withdrawn",
  },
  {
    order: 5,
    name: "Construction permit",
    statusLabel: "Construction permit",
    analyzeLabel: "Approved",
    value: "construction_permitted",
  },
  {
    order: 6,
    name: "Construction commenced",
    statusLabel: "Construction commenced",
    analyzeLabel: "Under Construction",
    value: "construction_commenced",
  },
  {
    order: 7,
    name: "Construction completed",
    statusLabel: "Construction completed",
    analyzeLabel: "Completed",
    value: "construction_completed",
  },
  {
    order: 8,
    name: "Remeasurement Created",
    statusLabel: "Remeasurement Created",
    analyzeLabel: "Completed",
    value: "remeasurement_created",
  },
  {
    order: 9,
    name: "Remeasurement Retired",
    statusLabel: "Remeasurement Retired",
    value: "remeasurement_retired",
  },
  {
    order: 10,
    name: "Proposed demolition",
    statusLabel: "Proposed demolition",
    analyzeLabel: "Completed",
    value: "demolition_proposed",
  },
  {
    order: 11,
    name: "Demolition permit",
    statusLabel: "Demolition permit",
    analyzeLabel: "Completed",
    value: "demolition_permitted",
  },
  {
    order: 12,
    name: "Demolished",
    statusLabel: "Demolished",
    value: "demolished",
  },
];

export default {
  PropertyRight: {
    "Fee simple": parcelMilestones,
    "Reciprocal Easement Agreement": parcelMilestones,
    Leasehold: contractMilestones,
    Condominium: contractMilestones,
    "Co-op": contractMilestones,
    "Unused Development Rights": airRightMilestones,
  },
  LandCovering: developmentMilestones,
  FloorArea: floorAreaMilestones,
  FloorAreaLayout: developmentMilestones,
  PropertyEnhancement: [
    {
      order: 1,
      name: "Created",
      statusLabel: "Created",
      value: "created",
    },
    {
      order: 2,
      name: "Destroyed",
      statusLabel: "Destroyed",
      value: "destroyed",
    },
  ],
  Investment: [
    {
      order: 1,
      name: "Available",
      statusLabel: "Available",
      value: "live",
      openDateFieldName: ["live_date"],
      closeDateFieldName: [
        "under_contract_date",
        "closed_date",
        "withdrawn_date",
      ],
    },
    {
      order: 2,
      name: "Under contract",
      statusLabel: "Under contract",
      value: "under_contract",
      openDateFieldName: ["under_contract_date"],
      closeDateFieldName: ["closed_date", "withdrawn_date"],
    },
    {
      order: 3,
      name: "Withdrawn",
      statusLabel: "Withdrawn",
      value: "withdrawn",
      openDateFieldName: ["withdrawn_date"],
      closeDateFieldName: ["ignore_date"],
    },
    {
      order: 4,
      name: "Closed",
      statusLabel: "Closed",
      value: "closed",
      openDateFieldName: ["closed_date"],
      closeDateFieldName: ["ignore_date"],
    },
  ],
  SpaceAvailability: [
    {
      order: 1,
      name: "Available",
      statusLabel: "Available",
      value: "live",
      openDateFieldName: ["live_date"],
      closeDateFieldName: [
        "under_contract_date",
        "closed_date",
        "withdrawn_date",
      ],
    },
    {
      order: 2,
      name: "Letter of Intent",
      statusLabel: "Under contract",
      value: "under_contract",
      openDateFieldName: ["under_contract_date"],
      closeDateFieldName: ["closed_date", "withdrawn_date"],
    },
    {
      order: 3,
      name: "Withdrawn",
      statusLabel: "Withdrawn",
      value: "withdrawn",
      openDateFieldName: ["withdrawn_date"],
      closeDateFieldName: ["ignore_date"],
    },
    {
      order: 4,
      name: "Closed",
      statusLabel: "Closed",
      value: "closed",
      openDateFieldName: ["closed_date"],
      closeDateFieldName: ["ignore_date"],
    },
  ],
  SpaceUsage: contractMilestones,
  SpaceUsageOccupancy: occupancyMilestones,
  Loan: [
    {
      order: 1,
      name: "Active",
      statusLabel: "Active",
      value: "active",
      dateValue: "origination",
      openDateFieldName: ["origination_date"],
      closeDateFieldName: ["retired_date"],
    },
    {
      order: 2,
      name: "Retired",
      statusLabel: "Retired",
      value: "retired",
      openDateFieldName: ["retired_date"],
      closeDateFieldName: ["ignore_date"],
    },
  ],
  CompanyInvolvement: [
    {
      order: 1,
      name: "Engaged",
      statusLabel: "Engaged",
      value: "engaged",
    },
    {
      order: 2,
      name: "Terminated",
      statusLabel: "Terminated",
      value: "terminated",
    },
  ],
  Hunt: huntMilestones,
};
